import * as React from "react";
import { throwSubmissionError } from "../../utils/errors";
import Form from "../common/Form";
import Warning from "../common/Warning";
import {
  Typography,
  Theme,
  WithStyles,
  Button,
  withStyles,
  createStyles,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { Field } from "react-final-form";
import { required, compose, maxLength } from "../../utils/validators";
import Input from "../common/BootstrapTextField";
import { FieldValidator } from "final-form";
import { requestJSON } from "../../utils/http";
import { RouteComponentProps } from "@reach/router";
import { WithAuth, withAuth } from "../auth/withAuth";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Auth } from "aws-amplify";
import { navigate } from "gatsby-link";

const styles = (theme: Theme) =>
  createStyles({
    submit: {
      width: "100%",
      marginTop: theme.spacing(4),
      textTransform: "capitalize",
      height: 50,
      fontSize: 18
    },
    showPassword: {
      color: theme.palette.grey[500],
      marginRight: -theme.spacing(2)
    }
  });

export interface RegistrationData {
  login: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  fundName: string;
}

interface Props
  extends WithStyles<typeof styles>,
    WithAuth,
    RouteComponentProps<{}> {}

interface State {
  showPassword?: boolean;
  showConfirmPassword?: boolean;
}

const validate: FieldValidator = (field: string, form: RegistrationData) => {
  if (form.password !== field) {
    return "Passwords do not match";
  }

  return undefined;
};

class Register extends React.Component<Props, State> {
  state = { showPassword: false, showConfirmPassword: false };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Form<RegistrationData>
          onSubmit={this.register}
          showErrorToasts={false}
        >
          {({ handleSubmit, submitting, submitError, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                {submitError && (
                  <Warning>
                    <Typography color="primary" paragraph>
                      {submitError}
                    </Typography>
                  </Warning>
                )}

                <Field
                  name="login"
                  component={Input}
                  label="Email"
                  fullWidth
                  margin="dense"
                  autoComplete="username"
                  validate={required("Email address is required")}
                />
                <Field
                  name="password"
                  component={Input}
                  label="Password"
                  autoComplete="new-password"
                  type={this.state.showPassword ? "text" : "password"}
                  fullWidth
                  margin="dense"
                  helperText="Requires at least 8 characters, a lowercase letter, an uppercase letter, a number and no parts of your username."
                  validate={required("Password is required")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.togglePassword}
                          className={classes.showPassword}
                          tabIndex={-1}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Field
                  name="confirmPassword"
                  component={Input}
                  label="Confirm Password"
                  autoComplete="new-password"
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  fullWidth
                  margin="dense"
                  validate={compose(
                    required("Password is required"),
                    validate
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.toggleConfirmPassword}
                          className={classes.showPassword}
                          tabIndex={-1}
                        >
                          {this.state.showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Field
                  name="firstName"
                  label="First Name"
                  component={Input}
                  fullWidth
                  margin="dense"
                  validate={required("First Name is required")}
                />
                <Field
                  name="lastName"
                  label="Last Name"
                  component={Input}
                  fullWidth
                  margin="dense"
                  validate={required("Last Name is required")}
                />

                <Field
                  name="fundName"
                  label="Fund Name"
                  component={Input}
                  fullWidth
                  margin="dense"
                  validate={compose(
                    required("Fund Name is required"),
                    maxLength(100, "Fund Name can be at most 100 characters")
                  )}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={submitting}
                >
                  Continue
                </Button>
              </form>
            );
          }}
        </Form>
      </React.Fragment>
    );
  }

  register = async (data: RegistrationData) => {
    try {
      await requestJSON("/register", "POST", data);

      await Auth.signIn(data.login, data.password);

      navigate("/implicit/callback");
    } catch (ex) {
      return throwSubmissionError(ex);
    }
  };

  private togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  private toggleConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };
}

export default withAuth(withStyles(styles)(Register));
