import React from "react";
import Register from "../components/accounts/Register";

class RegisterPage extends React.Component {
  render() {
    return <Register />;
  }
}

export default RegisterPage;
